import React, { createContext, useContext, useState, ReactNode } from "react";

// Define a type for your context value
interface EventContextType {
  eventId: number | null;
  setEventId: React.Dispatch<React.SetStateAction<number | null>>;
}

// Initialize with null, but assign correct type
const EventContext = createContext<EventContextType | null>(null);

export const useEventContext = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("useEventContext must be used within an EventProvider");
  }
  return context;
};

export const EventProvider = ({ children }: { children: ReactNode }) => {
  const [eventId, setEventId] = useState<number | null>(null);

  return <EventContext.Provider value={{ eventId, setEventId }}>{children}</EventContext.Provider>;
};
