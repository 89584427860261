import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
  }
`;

const PreviewBtn = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 12px 40px;
  font-size: 13px;
  width: 240px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 10rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

type PreviewProps = {
  event: any;
};

const Preview: React.FC<PreviewProps> = ({ event }) => {
  return (
    <PreviewContainer>
      <StyledLink to={`${routeNames.eventPreview}/${event?.id}`}>
        <PreviewBtn>Click to Preview</PreviewBtn>
      </StyledLink>
    </PreviewContainer>
  );
};

export default Preview;
