import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { tqMutationKeys, tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

type CreateEventPayload = {
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
};

export interface CreateEventResponse {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  type: string;
  about: string;
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  createdAt: ISODateString;
  updatedAt: ISODateString;
}

type UseCreateEventResult = UseMutationResult<CreateEventResponse, Error, CreateEventPayload>;

export function useCreateEvent(): UseCreateEventResult {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: CreateEventPayload) => {
      const res = await EvendyApi.post<CreateEventResponse>("event", payload); // Correct type here
      return res.data; // This will automatically unwrap the Promise
    },
    mutationKey: [tqMutationKeys.event.create],
    onError(createEventError: Error) {
      console.error("Error creating event:", createEventError);
    },
    onSuccess(data) {
      queryClient.invalidateQueries({ queryKey: [tqQueryKeys.event.getEvents] });
      console.log("Event created successfully:", data);
    },
  });
}
