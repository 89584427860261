import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Smiley } from "images/organizerevents/face-happy.svg";
import { ReactComponent as CheckIcon } from "images/organizerevents/Checkicon.svg";
import { ReactComponent as Close } from "images/organizerevents/x-close.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";

const Container = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 1rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #f9fafb;
  padding: 1rem;
  border-radius: 8px;
`;

const LargeText = styled.p`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #1d2939;
  margin: 0;
`;

const SmallText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #475467;
  margin: 0;
`;

const TinyText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #475467;
  margin: 0;
`;

const BlueText = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: right;

  color: #1390e6;
  margin: 0;
`;

const SmallBoldText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1d2939;
  margin: 0;
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SecondLine = styled.div``;

const Line = styled.div`
  width: 100%;
  border: 0.5px solid rgba(226, 232, 240, 1);
`;

const Benefits = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const SelectPlan = styled.button`
  background-color: #ffffff;
  color: #1390e6;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Attendeesplans = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <StyledLink to={routeNames.attendeesHowItWorks}>
        <CloseContainer>
          <Close />
        </CloseContainer>
      </StyledLink>
      <Top>
        <LargeText>Device Limit</LargeText>
        <SmallText>
          You currently have a 2 device limit when it comes
          <br />
          to checking-in your attendees. meaning you can
          <br />
          only use this application on 2 devices.
        </SmallText>
      </Top>
      <Top>
        <SmallBoldText>Pricing Plans</SmallBoldText>
        <SmallText>Select what best fits your organisation or event.</SmallText>
      </Top>
      <PlansContainer>
        <Plan>
          <FirstLine>
            <Smiley />
            <BlueText>Free</BlueText>
          </FirstLine>
          <SecondLine>
            <SmallBoldText>Free Plan</SmallBoldText>
          </SecondLine>
          <Line />
          <Benefits>
            <CheckIcon />
            <TinyText> 2 Devices Log-in</TinyText>
          </Benefits>
          <Benefits>
            <CheckIcon />
            <TinyText>Basic check in functunality</TinyText>
          </Benefits>
          <SelectPlan>select plan</SelectPlan>
        </Plan>
      </PlansContainer>
    </Container>
  );
};

export default Attendeesplans;
