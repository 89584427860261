import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type DeleteTicketPayload = {
  id: number;
};

interface DeleteTicketResponse {
  success: boolean;
  message: string;
}

type UseDeleteTicketResult = UseMutationResult<DeleteTicketResponse, Error, DeleteTicketPayload>;

export function useDeleteEventTicket(): UseDeleteTicketResult {
  return useMutation({
    mutationFn: async (payload: DeleteTicketPayload) => {
      const { id } = payload;
      const res = await EvendyApi.delete<Promise<DeleteTicketResponse>>(`ticket/${id}`);
      return res.data;
    },
    mutationKey: [tqMutationKeys.ticket.delete],
    onError(deleteError: Error) {
      console.log({ deleteError });
    },
    onSuccess(data) {
      console.log("ticket deleted successfully", data);
    },
  });
}
