import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Form, Input, Select } from "antd";
import { AppModal } from "ui/components/base";
import { ReactComponent as Notice } from "images/vendorprofile/Vectornotice.svg";
import { ModalBodyContainer, NoticeDisplay, NoticeText, Text } from "app/pages/organizer/vendorsprofile/Packages";
import { useCreatePackage } from "lib/hooks/vendorpackage";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { getSession } from "lib/utils/utils";
import { Link, Outlet, NavLink, useParams, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import { Divider } from "antd";
import { ReactComponent as TotalLogo } from "images/vendorprofile/Frametotal.svg";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TicketDetails = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: 100vh; /* Adjust height as needed */
  margin-top: 0.5rem;
`;

const OrderSummary = styled.div`
  margin-top: 0.5rem;
  width: 300px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SummaryTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const SummaryBottom = styled.div``;

const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  .tab-link {
    padding-top: 10px;
    padding-right: 50px;
    text-decoration: none;
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    border-top: 2px solid rgba(246, 246, 246, 1);
  }

  .active-tab {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #1390e6;
    border-top: 2px solid #1390e6;
  }

  @media (max-width: 768px) {
    gap: 10px;
    .tab-link {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    gap: 10px;
    .tab-link {
      font-size: 12px;
    }
  }
`;

const CustomDivider = styled(Divider)`
  height: 100%; /* Adjust the height as needed */
  margin: 0;
`;

const CustomDividerBlue = styled(Divider)`
  margin: 0;
  border-color: #1390e6;
`;

const OrderSummarybtn = styled.button`
  background-color: #f6f6f6;
  color: #121212;
  border: none;
  border-radius: 8px;
  padding: 8px;
  font-size: 13px;
  width: 310px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const EventName = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
`;

const EventTime = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const Quantity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`;

const Total = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

const TotalLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const TotalRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
`;

const QuantityHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const { TextArea } = Input;

const TicketModal: React.FC = () => {
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const { tab } = useParams();
  const { id } = useParams(); // Fetch the dynamic id from the URL
  const navigate = useNavigate();

  useEffect(() => {
    if (!tab) {
      navigate(`${routeNames.ticketSelect}/${id}`);
    }
  }, [tab, navigate]);

  useEffect(() => {
    setRequestModalOpen(true);
  }, []);

  const handleRequestModalClose = () => {
    navigate(`${routeNames.eventDetail}/${id}`);
  };

  return (
    <AppModal
      open={true}
      onCancel={handleRequestModalClose}
      closable={false}
      header={<span>Get tickets</span>}
      size="xxl"
    >
      <Container>
        <TicketDetails>
          <TabContainer>
            <NavLink
              className={({ isActive }) => (isActive ? "active-tab tab-link" : "tab-link")}
              to={`/getticket/selectticket/${id}`}
            >
              Select Ticket
            </NavLink>

            <NavLink
              to={`/getticket/yourdetails/${id}`}
              className={({ isActive }) => (isActive ? "active-tab tab-link" : "tab-link")}
            >
              Your Details
            </NavLink>

            <NavLink
              to={`/getticket/checkout/${id}`}
              className={({ isActive }) => (isActive ? "active-tab tab-link" : "tab-link")}
            >
              Checkout
            </NavLink>
          </TabContainer>
          <Outlet />
        </TicketDetails>
        <CustomDivider type="vertical" />
        <OrderSummary>
          <SummaryTop>
            <OrderSummarybtn>Order Summary</OrderSummarybtn>
            <Details>
              <img src="/Images/Events/eventimg.png" width="89px" height="56px" style={{ borderRadius: "8px" }} />
              <EventDetails>
                <EventName>DevFest 2024</EventName>
                <EventTime>26 Dec 2023 at 04:00 PM</EventTime>
              </EventDetails>
            </Details>
            <Quantity>
              <QuantityHeader>
                <SmallText>Item</SmallText>
                <SmallText>Qty</SmallText>
                <SmallText>Amount</SmallText>
              </QuantityHeader>
              <CustomDivider type="horizontal" />
            </Quantity>
          </SummaryTop>
          <SummaryBottom>
            <CustomDividerBlue type="horizontal" />
            <Total>
              <TotalLeft>
                <TotalLogo /> <NoticeText>Total amount:</NoticeText>
              </TotalLeft>
              <TotalRight>
                <NoticeText>NGN 6000</NoticeText>
              </TotalRight>
            </Total>
          </SummaryBottom>
        </OrderSummary>
      </Container>
    </AppModal>
  );
};

export default TicketModal;
