import React, { useEffect } from "react";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Link, Outlet, NavLink, useParams, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import { EventProvider } from "ui/components/event/eventContext";

const Top = styled.div`
  p {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: rgba(18, 18, 18, 1);
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 10px;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

const ReturnButton = styled(Link)`
  text-decoration: none;
  color: #1390e6;
  display: flex;
  align-items: start;
  top: 50%;
  left: 50%;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* padding: 1rem; */
  margin-left: 2rem;
  margin-top: 1rem;
  gap: 7rem;
  @media (max-width: 768px) {
    gap: 30px;
  }

  @media (max-width: 480px) {
    gap: 20px;
    padding: 10px;
  }
`;

const Tab = styled.div``;
const Tabheader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -40px;
`;
const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding-bottom: 10px;

  .tab-link {
    padding-top: 10px;
    padding-right: 80px;
    text-decoration: none;
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    border-top: 2px solid rgba(246, 246, 246, 1);
  }

  .active-tab {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #1390e6;
    border-top: 2px solid #1390e6;
  }

  @media (max-width: 768px) {
    gap: 10px;
    .tab-link {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    gap: 10px;
    .tab-link {
      font-size: 12px;
    }
  }
`;

const NewEvent = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tab) {
      navigate(routeNames.eventDetails);
    }
  }, [tab, navigate]);

  return (
    <EventProvider>
      <Container>
        <ReturnButton to={routeNames.organizerHome}>
          <LeftOutlined style={{ color: "#1390e6" }} /> Back
        </ReturnButton>
        <Tab>
          <Tabheader>
            <Top>
              <p>Create Event</p>
            </Top>
            <TabContainer>
              <NavLink
                className={({ isActive }) => (isActive ? "active-tab tab-link" : "tab-link")}
                to={routeNames.eventDetails}
              >
                Event Details
              </NavLink>

              <NavLink
                to={routeNames.eventType}
                className={({ isActive }) => (isActive ? "active-tab tab-link" : "tab-link")}
              >
                Type & Location
              </NavLink>

              <NavLink
                to={routeNames.eventDate}
                className={({ isActive }) => (isActive ? "active-tab tab-link" : "tab-link")}
              >
                Date & Time
              </NavLink>

              <NavLink
                to={routeNames.eventTickets}
                className={({ isActive }) => (isActive ? "active-tab tab-link" : "tab-link")}
              >
                Event Tickets
              </NavLink>
            </TabContainer>
          </Tabheader>
          <Outlet />
        </Tab>
      </Container>
    </EventProvider>
  );
};

export default NewEvent;
