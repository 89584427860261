import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import Topbar from "ui/components/base/Topbar/Topbar";
import MobileTopBar from "ui/components/base/MobileTopBar/MobileTopBar";
import { routeNames } from "lib/routes";
import Sidebar, { SidebarItem } from "ui/components/Dashboard/Sidebar/Sidebar";
import { useGetUser } from "lib/hooks";
import { ReactComponent as HomeIcon } from "images/sidebar/Framehome.svg";
import { ReactComponent as EventIcon } from "images/sidebar/Frameevents.svg";
import { ReactComponent as VendorIcon } from "images/sidebar/Shop-Filledvendor.svg";
import { ReactComponent as MarketingIcon } from "images/sidebar/Shopmarketing.svg";
import { ReactComponent as LoanIcon } from "images/sidebar/Frameloan.svg";
import { ReactComponent as FinancesIcon } from "images/sidebar/Framefinances.svg";
import { ReactComponent as ReportsIcon } from "images/sidebar/Framereports.svg";
import { ReactComponent as HospitalityIcon } from "images/sidebar/Framehospitality.svg";
import { ReactComponent as HelpIcon } from "images/sidebar/Frame493help.svg";
import { ReactComponent as MyPackages } from "images/sidebar/mypackages.svg";
import { ReactComponent as Resources } from "images/sidebar/resources.svg";
import { ReactComponent as Requests } from "images/sidebar/vendorrequest.svg";
import { getSession } from "lib/utils/utils";

const AttendeesContainer = styled.div``;

const TopbarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
`;

const MobileTopbarWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 768px) {
    display: flex;
  }
`;

const DesktopTopbarWrapper = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Sidebar data for organizer user
const organizerSidebarData: SidebarItem[] = [
  { name: "Home", path: "/app/organizer/home", icon: HomeIcon },
  { name: "Events", path: "/app/organizer/events", icon: EventIcon },
  { name: "Vendor Marketplace", path: "/app/marketplace", icon: VendorIcon },
  { name: "Marketing", path: "/app/marketing", icon: MarketingIcon, comingSoon: true },
  { name: "Finances", path: "/app/finances", icon: FinancesIcon, comingSoon: true },
  { name: "Loan Applications", path: "/app/organizer/loan", icon: LoanIcon, comingSoon: true },
  { name: "Reports", path: "/app/organizer/reports", icon: ReportsIcon, comingSoon: true },
  { name: "Hospitality", path: "/app/hospitality", icon: HospitalityIcon, comingSoon: true },
  { name: "Help", path: "/app/help", icon: HelpIcon, comingSoon: true },
];

const Attendees = () => {
  const [usertype, setUsertype] = useState("");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data: user, isLoading, error } = useGetUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const session = getSession();
    if (session) {
      const user = session.userType;
      if (user === "vendor" || user === "organizer") {
        setUsertype(user);
      } else {
        console.error("Invalid userType in token");
      }
    } else {
      console.log("No session found");
    }
  }, []);

  let sidebarData: SidebarItem[] = [];
  switch (usertype) {
    case "organizer":
      sidebarData = organizerSidebarData;
      break;
    default:
      sidebarData = organizerSidebarData;
  }

  // Close the sidebar whenever the route changes on mobile view
  useEffect(() => {
    if (window.innerWidth <= 768 && isSidebarVisible) {
      setIsSidebarVisible(false);
      setIsSidebarOpen(false);
    }
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleContinue = () => {
    setIsModalVisible(true);

    navigate(routeNames.VerifyEmail);
  };
  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <AttendeesContainer>
      {isMobile ? (
        <div>
          <TopbarWrapper>
            <MobileTopbarWrapper>
              <MobileTopBar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
            </MobileTopbarWrapper>
          </TopbarWrapper>
          <Outlet />
        </div>
      ) : (
        <div>Only accessible on mobile devices.</div>
      )}
    </AttendeesContainer>
  );
};

export default Attendees;
