import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { routeNames } from "lib/routes";
import { useGetEventById } from "lib/hooks";
import { Divider } from "antd";
import TicketModal from "./TicketModal";
import { ReactComponent as LinkImg } from "images/landingpage/event/Link1-Bold-24px1.svg";
import { ReactComponent as Twitter } from "images/landingpage/event/Twitter.svg";
import { ReactComponent as Facebook } from "images/landingpage/event/Facebook.svg";
import { ReactComponent as LinkedIn } from "images/landingpage/event/linkedin.svg";
import { ReactComponent as DateLogo } from "images/landingpage/event/date.svg";
import { ReactComponent as Organizer } from "images/landingpage/event/organizer.svg";
import { ReactComponent as Googlemaps } from "images/landingpage/event/googlemap.svg";
import { ReactComponent as Location } from "images/landingpage/event/location.svg";

const EventDetailContainer = styled.div`
  margin: 3rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin: 1rem;
  }
`;

const BackTomarketPlace = styled.div`
  color: #1390e6;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: auto;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const DetailSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }
`;

const EventCalender = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 760px) {
    padding: 20px;
  }
`;

const EventTime = styled.div``;

const EventAbout = styled.div`
  max-width: 720px;
  height: auto;

  @media (max-width: 760px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const HeaderText = styled.h3`
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const EventName = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(19, 144, 230, 1);
`;

const EventParagraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(18, 18, 18, 1);
`;

const CountdownSection = styled.div``;

const CountdownParagraph = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(47, 47, 47, 1);
`;
const TimeLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(249, 250, 255, 1);
`;
const Time = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const TimeNum = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
`;

const TimeLet = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #595959;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(89, 89, 89, 1);
`;

const EventDate = styled.div`
  margin-top: 1rem;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  border: 1px solid rgba(226, 232, 240, 1);
`;

const EventLocation = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  border: 1px solid rgba(226, 232, 240, 1);
`;

const EventStartDate = styled.div`
  span {
    display: flex;
    align-items: center;
  }
`;

const EventEndDate = styled.div`
  span {
    display: flex;
    align-items: center;
  }
`;

const DateTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const DateBottom = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateTopRigth = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const DateBottomRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const EventDateIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const BoldText = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;

  color: rgba(18, 18, 18, 1);
`;

const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  margin-top: 0;
  margin-bottom: 0;
  color: rgba(89, 89, 89, 1);
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  margin-top: 50px;
`;

const DirectionButton = styled(Button)`
  padding: 4px 6px 4px 6px;
  gap: 8px;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  background-color: #f9faff;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CustomDivider = styled(Divider)`
  height: 50px; /* Adjust the height as needed */
  margin: 0 1rem; /* Optional: Adjust spacing between elements */
`;

const EventPreview: React.FC = () => {
  const [eventId, setEventId] = useState(0);
  const params = useParams<{ id?: string }>();
  const [custommodalOpen, setcustomModalOpen] = useState(false);
  const navigate = useNavigate();

  const { data: event, error: eventError } = useGetEventById({ id: eventId });
  // Initialize state for time left
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setEventId(id);
  }, [params.id]);

  useEffect(() => {
    if (event?.startDate) {
      console.log("Event Start Date:", event.startDate);
      const targetDate = new Date(event.startDate); // Update based on event's startDate

      const calculateTimeLeft = () => {
        const now = new Date();
        const difference = targetDate.getTime() - now.getTime();

        let timeLeft = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };

        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }

        return timeLeft;
      };

      // Set interval to update countdown every second
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearInterval(timer); // Clear interval on unmount
    }
  }, [event?.startDate]);

  const onClick = () => {
    navigate("/getticket/selectticket");
  };

  return (
    <EventDetailContainer>
      <StyledLink to={`${routeNames.organizerEventManagement}/${eventId}`}>
        <BackTomarketPlace>
          <LeftOutlined /> Close preview
        </BackTomarketPlace>
      </StyledLink>

      <EventImage src="/Eventimages/Event_Banner.png" />

      <DetailSection>
        <EventAbout>
          <HeaderText>About Event</HeaderText>
          <EventName>{event?.title}</EventName>

          <EventParagraph>{event?.about}</EventParagraph>
        </EventAbout>

        <EventCalender>
          <HeaderSection>
            <HeaderText>Event Details</HeaderText>
            <IconWrapper>
              <LinkImg />
              <Twitter />
              <Facebook />
              <LinkedIn />
            </IconWrapper>
          </HeaderSection>
          <EventTime>
            <CountdownSection>
              <CountdownParagraph>Time Left:</CountdownParagraph>

              <TimeLabelContainer>
                <Time>
                  <TimeNum>{timeLeft.days}</TimeNum>
                  <TimeLet>Days</TimeLet>
                </Time>
                <span>:</span>
                <Time>
                  <TimeNum>{timeLeft.hours}</TimeNum>
                  <TimeLet>Hours</TimeLet>
                </Time>
                <span>:</span>
                <Time>
                  <TimeNum> {timeLeft.minutes}</TimeNum>
                  <TimeLet>Minuites</TimeLet>
                </Time>
                <span>:</span>
                <Time>
                  <TimeNum>{timeLeft.seconds}</TimeNum>
                  <TimeLet>Seconds</TimeLet>
                </Time>
              </TimeLabelContainer>
            </CountdownSection>
          </EventTime>

          <EventDate>
            <EventStartDate>
              <DateTop>
                <DateLogo />
                <Paragraph>Start date</Paragraph>
              </DateTop>
              <DateBottom>
                <BoldText>26 Dec 2023 at 04:00 PM</BoldText>
              </DateBottom>
            </EventStartDate>
            <CustomDivider type="vertical" />
            <EventEndDate>
              <DateTopRigth>
                <DateLogo />
                <Paragraph>End date</Paragraph>
              </DateTopRigth>
              <DateBottomRight>
                <BoldText>26 Dec 2023 at 04:00 PM</BoldText>
              </DateBottomRight>
            </EventEndDate>
          </EventDate>

          <EventLocation>
            <EventStartDate>
              <DateTop>
                <Location />
                <Paragraph>Location</Paragraph>
              </DateTop>
              <BoldText>Filmhouse - Lekki, Lagos State, Nigeria</BoldText>
            </EventStartDate>
            <DirectionButton>
              <Googlemaps />
              Directions
            </DirectionButton>
          </EventLocation>

          <EventDate>
            <EventStartDate>
              <DateTop>
                <Organizer />
                <Paragraph>Event Organizer</Paragraph>
              </DateTop>
              <BoldText>Evendy Technologies</BoldText>
            </EventStartDate>
          </EventDate>
          <StyledButton onClick={onClick}>Get tickets</StyledButton>
        </EventCalender>
      </DetailSection>
    </EventDetailContainer>
  );
};

export default EventPreview;
