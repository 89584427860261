import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

export type EditTicketPayload = {
  id: number;
  data: {
    id: number;
    category?: string;
    type?: string;
    description?: string;
    quantity?: number;
    price?: number;
    image?: string;
    salesStartDate?: string;
    salesEndDate?: string;
    minQuantity?: number;
    maxQuantity?: number;
    eventId: number;
    createdAt?: string;
    updatedAt?: string;
  };
};

interface EventDetails {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
  type: string;
  occurence: string;
  timezone: string;
  startDate: string;
  endDate: string;
  location: string;
  publishStatus: string;
  createdAt: string;
  updatedAt: string;
}

interface EditTicketResponse {
  id: number;
  category: string;
  type: string;
  description: string;
  quantity: number;
  price: number;
  image: string;
  salesStartDate: string;
  salesEndDate: string;
  minQuantity: number;
  maxQuantity: number;
  eventId: number;
  createdAt: string;
  event: EventDetails;
  updatedAt: string;
}

// Define the mutation result type
type UseEditTicketResult = UseMutationResult<EditTicketResponse, Error, EditTicketPayload>;

// Define the useEditPackage hook
export function useEditEventTicket(): UseEditTicketResult {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: EditTicketPayload) => {
      const { id, data } = payload;
      const res = await EvendyApi.patch<Promise<EditTicketResponse>>(`ticket/${id}`, data);
      return res.data;
    },
    mutationKey: [tqMutationKeys.ticket.edit],
    onError(editError: Error) {
      console.log({ editError });
    },
    onSuccess(data) {
      console.log("ticket updated successfully", data);
    },
  });
}
