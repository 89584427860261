import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, message } from "antd";
import styled from "styled-components";
import { useGetEventTicketsByEventId } from "lib/hooks/ticketing";
import { AppModal } from "ui/components/base";
import { SettingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import NoTicket from "../ticket/noTicket";
import TicketCard from "../ticket/ticketCard";
import CreateTicketModal from "../ticket/ticketModal";
// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MiddleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Bottom = styled.div``;

const Title = styled.h2`
  font-size: 18px;
`;

const CreateTicketButton = styled(Button)`
  display: flex;
  align-items: center;
  border-color: #1890ff;
  color: #1890ff;
  height: 46px;
`;

const SettingsLink = styled.a`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 16px;
  color: #1890ff;
  text-decoration: none;

  font-weight: 600;
  &:hover {
    color: #40a9ff;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const CancelButton = styled(Button)`
  border-color: #d9d9d9;
  color: #000;
`;

const SaveButton = styled(Button)`
  background-color: #1890ff;
  color: #fff;
  border-color: #1890ff;

  &:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
`;

const Ticketcontainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px; /* Adjust the gap as needed */
    width: 100%;
  }
`;

type EventTicketsProps = {
  event: any;
};

const EventTickets: React.FC<EventTicketsProps> = ({ event }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventId, setEventId] = useState(0);
  const params = useParams<{ id?: string }>();

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setEventId(id);
  }, [params.id]);
  // Open the modal
  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  // Close the modal
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const { data, isLoading, isError, error, refetch } = useGetEventTicketsByEventId({ eventid: event?.id });
  return (
    <Container>
      <TicketsContainer>
        <Top>
          <Title>Event Tickets</Title>
          {/* Right side - General Settings */}
          <SettingsLink href="#">
            <SettingOutlined />
            General Settings
          </SettingsLink>
        </Top>
        <Bottom>
          {isLoading ? (
            <p>Loading...</p>
          ) : isError || (data && data.length === 0) ? (
            <NoTicket />
          ) : (
            <Ticketcontainer>
              {data?.map(ticket => <TicketCard key={ticket.id} ticket={ticket} onTicketAdded={refetch} />)}
            </Ticketcontainer>
          )}
        </Bottom>
      </TicketsContainer>
      <MiddleRow>
        {/* Left side - Title and Create Ticket Button */}
        <div>
          <CreateTicketButton icon={<PlusCircleOutlined />} onClick={handleModalOpen}>
            Create new ticket
          </CreateTicketButton>
        </div>
      </MiddleRow>

      {/* Modal for Creating Ticket */}
      <AppModal open={isModalVisible} header="Create Ticket" onCancel={handleModalClose} size="xxl">
        <CreateTicketModal closecreateticket={handleModalClose} onTicketAdded={refetch} eventId={eventId} />
      </AppModal>
    </Container>
  );
};

export default EventTickets;
