import React, { useState } from "react";
import {
  SiteHeader,
  Nav,
  MenuContainer,
  MenuColoumn,
  ArrowContainer,
  HoverContainer,
  NavMenu,
  NavText,
  LogoContainer,
  StyledServiceLink,
  Login,
  CollaspeButton,
  StyledLi,
  RightAlign,
  StyledButton,
  StyledHeaderLink,
  HeaderPlaceholder,
  MobileNav,
  MobileNavMenu,
  AuthSection,
  MobileAuthSection,
  LogoSection,
  SignUpLink,
  LoginLink,
} from "./Header.style";
import { Drawer, Dropdown, Menu, Space } from "antd";
import { MenuOutlined, DownOutlined, MailTwoTone } from "@ant-design/icons";
import { AppButton, Menu02, WebsiteContainer } from "ui/components/base";
import { useGetUser } from "lib/hooks/auth/useGetUser";
import { routeNames } from "lib/routes";
import { Link, useLocation } from "react-router-dom";
import { getSession, getUserHomeRoute } from "lib/utils";
import { ReactComponent as Ticket } from "images/landingpage/services/navticketing.svg";
import { ReactComponent as Event } from "images/landingpage/services/naveventmarketing.svg";
import { ReactComponent as EventAnalysis } from "images/landingpage/services/navanalytics.svg";
import { ReactComponent as EventFinancing } from "images/landingpage/services/navevent.svg";
import { ReactComponent as EventMan } from "images/landingpage/services/naveventmanagement.svg";
import Hover from "images/landingpage/services/Vector4.png";
import { ReactComponent as VendorSourcing } from "images/landingpage/services/navvendorsourcing.svg";
import { ReactComponent as Arrow } from "images/landingpage/services/navarrow-right.svg";

const Header = () => {
  const [showLinks, setShowLinks] = useState<boolean>(false);

  const { data: user } = useGetUser();
  const userHome = getUserHomeRoute(user);

  const location = useLocation();

  const toggleLinks = () => {
    setShowLinks(!showLinks);
    console.log("showLinks:", showLinks);
  };

  const servicesSubMenu = (
    <Menu style={{ background: "white" }}>
      <MenuContainer>
        <MenuColoumn>
          <Menu.Item key="1" style={{ background: "white" }}>
            <StyledServiceLink to={routeNames.ticketingPage}>
              <LogoContainer>
                <Ticket />
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <NavText>Ticketing</NavText>
              </LogoContainer>
              <ArrowContainer>
                <Arrow />
              </ArrowContainer>
            </StyledServiceLink>
          </Menu.Item>
          <Menu.Item key="1" style={{ background: "white" }}>
            <StyledServiceLink to={routeNames.eventMarketing}>
              <LogoContainer>
                <Event />
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <NavText>Event Marketing</NavText>
              </LogoContainer>
              <ArrowContainer>
                <Arrow />
              </ArrowContainer>
            </StyledServiceLink>
          </Menu.Item>
          <Menu.Item key="1" style={{ background: "white" }}>
            <StyledServiceLink to={routeNames.analysisPage}>
              <LogoContainer>
                <EventAnalysis />
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <NavText>Event Anayltics</NavText>
              </LogoContainer>
              <ArrowContainer>
                <Arrow />
              </ArrowContainer>
            </StyledServiceLink>
          </Menu.Item>
        </MenuColoumn>

        <MenuColoumn>
          <Menu.Item key="0" style={{ background: "white" }}>
            <StyledServiceLink to={routeNames.financialServices}>
              <LogoContainer>
                <EventFinancing />
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <NavText>Event Finance</NavText>
              </LogoContainer>
              <ArrowContainer>
                <Arrow />
              </ArrowContainer>
            </StyledServiceLink>
          </Menu.Item>

          <Menu.Item key="3" style={{ background: "white" }}>
            <StyledServiceLink to={routeNames.eventManagement}>
              <LogoContainer>
                <EventMan />
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <NavText>Event Management</NavText>
              </LogoContainer>
              <ArrowContainer>
                <Arrow />
              </ArrowContainer>
            </StyledServiceLink>
          </Menu.Item>

          <Menu.Item key="5" style={{ background: "white" }}>
            <StyledServiceLink to={routeNames.eventVendorSourcing}>
              <LogoContainer>
                <VendorSourcing />
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <NavText>Vendor Sourcing</NavText>
              </LogoContainer>
              <ArrowContainer>
                <Arrow />
              </ArrowContainer>
            </StyledServiceLink>
          </Menu.Item>
        </MenuColoumn>
      </MenuContainer>
    </Menu>
  );

  return (
    <>
      <SiteHeader>
        <WebsiteContainer>
          <Nav>
            <LogoSection>
              <StyledHeaderLink to={"/"} replace>
                <img src="/LandingPage/Logo.svg" alt="Evendy Logo" />
              </StyledHeaderLink>
            </LogoSection>
            <NavMenu>
              <StyledLi>
                <Dropdown overlay={servicesSubMenu} trigger={["hover"]} placement="bottom">
                  <StyledHeaderLink
                    to={routeNames.servicesHomePage}
                    className="ant-dropdown-link"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                  >
                    Services
                  </StyledHeaderLink>
                </Dropdown>
              </StyledLi>
              <StyledLi>
                <StyledHeaderLink to={routeNames.event}>Explore</StyledHeaderLink>
              </StyledLi>{" "}
              <StyledLi>
                <StyledHeaderLink to={routeNames.about}>About Us</StyledHeaderLink>
              </StyledLi>
              <StyledLi>
                <StyledHeaderLink to={routeNames.contact}>Contact</StyledHeaderLink>
              </StyledLi>{" "}
            </NavMenu>
            <CollaspeButton onClick={toggleLinks}>
              <Menu02 />
            </CollaspeButton>
            <AuthSection>
              {user ? (
                <AppButton as={Link} type="button" variant="secondaryGray" to={userHome}>
                  <Space>
                    <MailTwoTone /> {user.email}
                  </Space>
                </AppButton>
              ) : (
                <>
                  <SignUpLink to={routeNames.authSignup}>Sign Up</SignUpLink>

                  <LoginLink to={routeNames.authLogin}>Log in</LoginLink>
                </>
              )}
            </AuthSection>
          </Nav>
        </WebsiteContainer>
      </SiteHeader>

      <HeaderPlaceholder />
      <Drawer
        open={showLinks}
        onClose={() => setShowLinks(false)}
        title={
          <StyledHeaderLink to={"/"} replace>
            <img src="/LandingPage/Logomobile.svg" alt="Evendy Logo" />
          </StyledHeaderLink>
        }
      >
        <Nav>
          <MobileNavMenu>
            <StyledLi>
              <StyledHeaderLink to={routeNames.homepage}>Home</StyledHeaderLink>
            </StyledLi>
            <StyledLi>
              <Dropdown overlay={servicesSubMenu} trigger={["hover"]}>
                <StyledHeaderLink
                  to={routeNames.servicesHomePage}
                  className="ant-dropdown-link"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                >
                  Services <DownOutlined />
                </StyledHeaderLink>
              </Dropdown>
            </StyledLi>
            <StyledLi>
              <StyledHeaderLink to={routeNames.event}>Explore</StyledHeaderLink>
            </StyledLi>{" "}
            <StyledLi>
              <StyledHeaderLink to={routeNames.about}>About Us</StyledHeaderLink>
            </StyledLi>
            <StyledLi>
              <StyledHeaderLink to={routeNames.contact}>Contact</StyledHeaderLink>
            </StyledLi>{" "}
            <MobileAuthSection>
              <SignUpLink to={routeNames.authLogin} state={{ backgroundLocation: location }}>
                Log in
              </SignUpLink>
              <LoginLink to={routeNames.authSignup}>Sign Up</LoginLink>
            </MobileAuthSection>
          </MobileNavMenu>
        </Nav>
      </Drawer>
    </>
  );
};
export default Header;
