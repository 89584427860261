import { CloseOutlined } from "@ant-design/icons";
import { Modal, ModalProps } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { screenSizes } from "ui/theme";

const heights = {
  header: 70,
  displayPadding: 200, // changed from 40
};

const getSizeValue = (size: "sm" | "md" | "lg" | "xl" | "xxl") => {
  const sizeMap = {
    sm: "400px",
    md: "568px",
    lg: "800px",
    xl: "1000px",
    xxl: "1200px",
  };
  return sizeMap[size] || sizeMap.md;
};

// export const maxModalWidth = "638px";
export const maxModalWidth = "1200px";

const AntModal = styled(Modal)<{ size?: "sm" | "md" | "lg" | "xl" | "xxl" }>`
  .ant-modal-content {
    height: calc(100vh - ${heights.displayPadding}px);
    width: 100%;
    max-width: ${maxModalWidth};
    padding: 0;
    margin: 0;

    .ant-modal-close {
      display: none;
    }
  }

  min-width: 100vw;
  @media (min-width: ${screenSizes.mobileL}) {
    .ant-modal-content {
      width: 100%;
      max-width: ${maxModalWidth};
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div<{ size?: "sm" | "md" | "lg" | "xl" | "xxl" }>`
  width: 100%;
  max-width: ${props => (props.size ? getSizeValue(props.size) : "100%")};
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - ${heights.displayPadding}px - ${heights.header}px);
`;

const Header = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 15px 15px 0 0;
  background: white;
  color: black;
  svg path {
    stroke: #fff;
  }

  font-size: 16px;
  font-weight: 600;
`;

interface AppModalProps extends ModalProps {
  open: boolean;
  header: ReactNode;
  children: ReactNode;
  onCancel: () => void;
  size?: "sm" | "md" | "lg" | "xl" | "xxl";
  hideHeader?: boolean; // Add this prop
}

export const AppModal: React.FC<AppModalProps> = ({
  open,
  children,
  onCancel,
  header,
  size,
  hideHeader,
  ...restProps
}) => {
  return (
    <AntModal
      {...restProps}
      open={open}
      onCancel={onCancel}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      size={size}
    >
      {!hideHeader && (
        <Header>
          {header} <CloseOutlined onClick={onCancel} width={34} height={34} style={{ cursor: "pointer" }} />
        </Header>
      )}
      <Body size={size}>{children}</Body>
    </AntModal>
  );
};
