import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input } from "antd";
import type { GetProp } from "antd";
import type { OTPProps } from "antd/es/input/OTP";
import { useNavigate } from "react-router-dom";
import { useDidMountEffect, useRequestEmailVerify, useVerify, useGetUser } from "lib/hooks";
import { appGet, getSession } from "lib/utils";
import { AppModal } from "ui/components/base";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 20px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const LargeText = styled.p`
  font-size: 13px;
  color: #595959;
`;

const SmallText = styled.p`
  font-size: 12px;
  color: #595959;
  margin-top: 0;
`;

const Line3 = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Linkto = styled.span<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? "#d9d9d9" : "#1390e6")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const Continue = styled.button<{ enabled: boolean }>`
  background-color: ${({ enabled }) => (enabled ? "#1390e6" : "#d9d9d9")};
  color: white;
  border: 1px solid;
  border-color: ${({ enabled }) => (enabled ? "#1390e6" : "#d9d9d9")};
  border-radius: 8px;
  padding: 12px 12px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  cursor: ${({ enabled }) => (enabled ? "pointer" : "not-allowed")};
`;

const VerifyEmail: React.FC = () => {
  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [token, setToken] = useState("");
  const [verifyEnabled, setVerifyEnabled] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  const { loading, requestVerifyEmail } = useRequestEmailVerify();
  const { data: user } = useGetUser();
  const userEmail = user?.email;

  useDidMountEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  const handleResendClick = () => {
    if (resendEnabled && userEmail) {
      const request = async (email: string) => {
        await requestVerifyEmail(email);
      };
      request(userEmail);
      setResendEnabled(false);
      setTimer(60);
    }
  };

  const verifyMutation = useVerify();

  const onChange: GetProp<typeof Input.OTP, "onChange"> = text => {
    setToken(text);
    setIsContinueEnabled(text.length === 6);
  };

  const sharedProps: OTPProps = {
    onChange,
  };

  const handleContinueClick = () => {
    setIsContinueEnabled(false);
    verifyMutation.mutate({ token });
  };

  useEffect(() => {
    if (verifyMutation.isSuccess) {
      setIsModalOpen(false);
      window.location.reload();
    }
  }, [verifyMutation.isSuccess]);

  useEffect(() => {
    if (userEmail) {
      const request = async (email: string) => {
        await requestVerifyEmail(email);
      };
      request(userEmail);
    }
  }, [userEmail]);

  const handleCancel = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <Container>
      <Top>
        <Header>Verify Email Address</Header>
        <SmallText>Input the 6-digit number we sent to the email provided to continue</SmallText>
      </Top>
      <LargeText>Input OTP Code</LargeText>
      <Input.OTP length={6} {...sharedProps} />
      <Line3>
        <LargeText>
          Didn’t receive any code?
          <Linkto onClick={handleResendClick} disabled={!resendEnabled}>
            Resend here
          </Linkto>
          {timer > 0 && <span> ({timer}s)</span>}
        </LargeText>
      </Line3>
      <Continue enabled={isContinueEnabled} disabled={!isContinueEnabled || loading} onClick={handleContinueClick}>
        Continue
      </Continue>
      {verifyMutation.isError && (
        <SmallText>
          Error: {appGet(verifyMutation.error, "response.data.message", verifyMutation.error.message)}
        </SmallText>
      )}
    </Container>
  );
};

export default VerifyEmail;
