import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

interface Event {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
  type: string;
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  location: string;
  publishStatus: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  vendorEmail: string;
  requests: object[];
  reviews: object[];
}

interface EventResponse {
  data: Event[];
  page: number;
  limit: number;
  totalCount: number;
}

type EventResult = UseQueryResult<EventResponse, Error>;

export type GetEventPayload = {
  page: number;
  limit: number;
  searchstring: string;
  timezone: string;
};

export function useGetEventExplore(payload: GetEventPayload): EventResult {
  const { page, limit, searchstring, timezone } = payload;

  // Filter out undefined or null params
  // Updated params
  const params = {
    page,
    limit,
    ...(searchstring && { search: searchstring }), // Match backend parameter
    ...(timezone && { timezone }),
  };

  return useQuery({
    queryKey: [tqQueryKeys.event.getExploreEvents, ...Object.values(params), page, limit, searchstring, timezone],
    queryFn: async (): Promise<EventResponse> => {
      const res = await EvendyApi.get<EventResponse>("event/explore", { params });
      console.log("API Response:", res.data);
      return res.data;
    },
    enabled: !!page && !!limit,
    retry: false,
  });
}
