import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { Checkbox, Tag } from "antd";
import { Button, Form, message, Input } from "antd";
import { useGetUser } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import SubmitButton from "../../profile/Button";
import { ReactComponent as Select } from "images/modal/Frameselect.svg";
import { useEditEventById } from "lib/hooks";
import { EditEventPayload } from "lib/hooks";

interface CollapsibleProps {
  isVisible: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  gap: 1rem;
`;

const CollapseButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapsibleContent = styled.div<CollapsibleProps>`
  max-height: ${props => (props.isVisible ? "500px" : "0")};
  overflow: hidden;
  margin-left: 1rem;
  transition: max-height 0.3s ease;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const OccurrenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;
const OccurrenceCard = styled.div<{ disabled?: boolean; isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 78px;
  border: 1px solid ${props => (props.disabled ? "#d9d9d9" : props.isSelected ? "rgba(28, 91, 135, 1)" : "#1890ff")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};
`;

const OccurrenceContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormBox = styled.div`
  margin-bottom: 10px;

  width: 100%;
`;

const OccurrenceLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const OccurrenceDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const EventTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const EventTypeCard = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 30%;
  height: 60px;
  border: 1px solid ${props => (props.selected ? "#1890ff" : "#d9d9d9")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const EventTypeCardDisabled = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 30%;
  height: 60px;
  border: 1px solid ${props => (props.selected ? "#1C5B87" : "#d9d9d9")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const Right = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EventTypeLabel = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${({ selected }) => (selected ? "#1C5B87" : "initial")};
`;

const EventTypeDescription = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ selected }) => (selected ? "#1C5B87" : "#888")};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const CardDisabled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const ComingSoonButton = styled.div`
  padding: 2px 5px;
  background-color: #fffefa;
  color: #665000;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  font-size: 9px;
  cursor: not-allowed;
  margin-left: auto;
  margin-right: 0.5rem;
`;

type TypeLocationProps = {
  event: any;
};

const TypeLocation: React.FC<TypeLocationProps> = ({ event }) => {
  const { data: user } = useGetUser();
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);
  const [isUpdating, setIsUpdating] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleCardClick = (type: string) => {
    setSelectedType(prevType => (prevType === type ? null : type));
  };

  const { mutate: updateEvent } = useEditEventById();

  //set initials
  useEffect(() => {
    if (event) {
      setSelectedType(event.type);
    }
  }, [event, form]);

  // Handle form submission
  const handleSubmit = async (values: any) => {
    try {
      // Construct the payload
      const payload: EditEventPayload = {
        id: event.id,
        data: {
          type: selectedType || "physical",
        },
      };

      // Update the event
      updateEvent(payload, {
        onSuccess: () => {
          message.success("Event updated successfully");
        },
        onError: error => {
          message.error(`Error updating event: ${error.message}`);
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        message.error(`Error processing event: ${error.message}`);
      } else {
        message.error("An unknown error occurred.");
      }
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Container>
      <CollapseButton onClick={toggleVisibility}>
        Type & Location
        {isVisible ? <DownOutlined /> : <RightOutlined />}
      </CollapseButton>

      <CollapsibleContent isVisible={isVisible}>
        <ContentContainer>
          <Top>
            <SmallText>Event Type</SmallText>
          </Top>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            style={{ width: "100%", margin: "0 auto" }}
            requiredMark={false}
          >
            <EventTypeContainer>
              <EventTypeCard selected={selectedType === "physical"} onClick={() => handleCardClick("physical")}>
                <Left>
                  <EventTypeLabel selected={selectedType === "physical"}>Physical event</EventTypeLabel>
                  <EventTypeDescription selected={selectedType === "physical"}>
                    At a venue or physical location
                  </EventTypeDescription>
                </Left>
                <Right visible={selectedType === "physical"}>
                  <Select />
                </Right>
              </EventTypeCard>

              <EventTypeCardDisabled selected={selectedType === "online"} disabled>
                <CardDisabled>
                  <EventTypeLabel>Online event</EventTypeLabel>
                  <ComingSoonButton>Coming Soon</ComingSoonButton>
                </CardDisabled>
                <EventTypeDescription>Virtual live stream hosting</EventTypeDescription>
              </EventTypeCardDisabled>

              <EventTypeCardDisabled selected={selectedType === "hybrid"} disabled>
                <CardDisabled>
                  <EventTypeLabel>Hybrid event</EventTypeLabel>
                  <ComingSoonButton>Coming Soon</ComingSoonButton>
                </CardDisabled>

                <EventTypeDescription>Host physically & virtually</EventTypeDescription>
              </EventTypeCardDisabled>
            </EventTypeContainer>

            <FormBox style={{ flex: 1 }}>
              <Form.Item label="Location / Venue" name="locationvenue" rules={[{ required: true, message: "" }]}>
                <Input style={{ width: "100%", fontSize: "0.8rem" }} />
              </Form.Item>
            </FormBox>

            <SubmitContainer>
              <SubmitButton form={form}>Save changes</SubmitButton>
            </SubmitContainer>
          </Form>
        </ContentContainer>
      </CollapsibleContent>
    </Container>
  );
};

export default TypeLocation;
