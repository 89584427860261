import React, { useState, useMemo, useEffect } from "react";
import { Form, Input, Select, message } from "antd";
import styled from "styled-components";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import SubmitButton from "../../profile/Button";
import EventImg from "images/organizerevents/Image.png";
import { useEditEventById } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { useGetUser } from "lib/hooks";
import { useGetEventCategories } from "lib/hooks/eventcategory";

interface CollapsibleProps {
  isVisible: boolean;
}

const { Option } = Select;
const { TextArea } = Input;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const CollapseButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapsibleContent = styled.div<CollapsibleProps>`
  max-height: ${props => (props.isVisible ? "800px" : "0")};
  margin-left: 1rem;
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const FormBox = styled.div`
  margin-bottom: 10px;

  width: 100%;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const EmailInput = styled(Input)`
  background-color: #d9d9d9;
  color: #9c9c9c;
`;

type EventDetailsProps = {
  event: any;
};

const EventDetails: React.FC<EventDetailsProps> = ({ event }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const { data: user } = useGetUser();
  const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useGetEventCategories();
  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);

  useEffect(() => {
    if (event) {
      form.setFieldsValue({
        eventtitle: event.title,
        organizer: event.organizerEmail,
        category: event.category,
        aboutevent: event.about,
      });
    }
  }, [event]);

  const { mutate: editEvent } = useEditEventById();
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (event !== null) {
        editEvent(
          {
            id: event.id,
            data: {
              title: values.eventtitle,
              organizerEmail: organizerEmail,
              category: values.category,
              about: values.aboutevent,
            },
          },
          {
            onSuccess: () => message.success("Event updated"),
            onError: () => message.error("Failed to update the event."),
          },
        );
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Container>
      <CollapseButton onClick={toggleVisibility}>
        Event Details
        {isVisible ? <DownOutlined /> : <RightOutlined />}
      </CollapseButton>

      <CollapsibleContent isVisible={isVisible}>
        <EventContainer>
          <Top>
            <SmallText>Event Banner</SmallText>
            <img src={EventImg} width="97%" height="223px" style={{ borderRadius: "10px" }} />
          </Top>

          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            initialValues={{ remember: true }}
            requiredMark={false}
          >
            <FormBox style={{ flex: 1 }}>
              <Form.Item label="Event Title" name="eventtitle" rules={[{ required: true, message: "" }]}>
                <Input style={{ width: "97%", fontSize: "0.8rem" }} />
              </Form.Item>
            </FormBox>

            <FormBox style={{ flex: 1 }}>
              <Form.Item label="organizer" name="organizer" rules={[{ required: true, message: "" }]}>
                <Input style={{ width: "97%", fontSize: "0.8rem" }} />
              </Form.Item>
            </FormBox>

            <FormBox style={{ flex: 1 }}>
              <Form.Item
                label="Event Category"
                name="category"
                rules={[{ required: true, message: "Please select a category" }]}
              >
                <Select
                  placeholder="Select category"
                  showSearch
                  loading={isCategoriesLoading}
                  style={{ width: "97%" }}
                  filterOption={(input, option) =>
                    typeof option?.children === "string"
                      ? String(option.children).toLowerCase().includes(input.toLowerCase())
                      : false
                  }
                >
                  {categoriesError && <Option disabled>Error fetching categories</Option>}
                  {categories?.map(category => (
                    <Option key={category.id} value={category.name}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </FormBox>

            <FormBox style={{ flex: 1 }}>
              <Form.Item label="About Event" name="aboutevent" rules={[{ required: true, message: "" }]}>
                <TextArea rows={4} style={{ width: "97%", fontSize: "0.8rem" }} />
              </Form.Item>
            </FormBox>

            <SubmitContainer>
              <SubmitButton form={form}>Save changes</SubmitButton>
            </SubmitContainer>
          </Form>
        </EventContainer>
      </CollapsibleContent>
    </Container>
  );
};

export default EventDetails;
