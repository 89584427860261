import React, { useState } from "react";
import styled from "styled-components";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";
import eventimage from "images/organizerevents/Rectangle1063(1).png";
import { ReactComponent as RightIcon } from "images/organizerevents/chevron-right.svg";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import { LeftOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

const HomeContainer = styled.div`
  margin-top: 4rem;
  padding-top: 1rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #f9fafb;
`;

const BackToHome = styled.div``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Category = styled.div`
  padding: 2px 5px;
  background-color: #fafbff;
  color: #1c5b87;

  border-radius: 8px;
  font-size: 9px;
  cursor: not-allowed;
`;

const Top = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Search = styled.div``;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const EventList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LargeText = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 0;
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #475467;
  margin: 0;
`;

const TicketCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  justify-content: space-between;
  border: none;
  border-radius: 8px;
  border: 1px solid #cecece;
  border-radius: 8px;
  background-color: #ffffff;
`;

const EventCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.3rem;
  justify-content: space-between;
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const EventImage = styled.img``;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const MediumBold = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
`;

const Tinytext = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
`;

const GreenText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #17b26a;
`;

const Right = styled.div``;

const Line = styled.div`
  width: 100%;
  border: 0.5px solid rgba(226, 232, 240, 1);
`;

const AttendeesEvent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // Add logic to perform search with the query
  };

  return (
    <HomeContainer>
      <Top>
        <StyledLink to={routeNames.organizermarketplace}>
          <BackToHome>
            <LeftOutlined /> Back to MarketPlace
          </BackToHome>
        </StyledLink>
        <EventCard>
          <Left>
            <EventImage src={eventimage} />
            <EventDetails>
              <Category>upcoming</Category>
              <MediumBold>BB Naija Finale</MediumBold>
              <Tinytext>Expected: 10,500</Tinytext>
              <Tinytext>19 July 2023</Tinytext>
            </EventDetails>
          </Left>
        </EventCard>
      </Top>
      <Search>
        <SearchBar onSearch={handleSearch} />
      </Search>
      <Filter>
        <Button style={{ fontSize: "13px", width: "48%" }}>
          <Space>
            Ticket status
            <DownOutlined />
          </Space>
        </Button>
        <Button style={{ fontSize: "13px", width: "48%" }}>
          <Space>
            Ticket type
            <DownOutlined />
          </Space>
        </Button>
      </Filter>
      <EventList>
        <Line />
        <TicketCard>
          <Left>
            <EventDetails>
              <Tinytext>Ticket Code: #456587</Tinytext>
              <Tinytext>Ticket Type: VVIP</Tinytext>
            </EventDetails>
          </Left>
          <Right>
            <GreenText>Checked-In</GreenText>
          </Right>
        </TicketCard>
        <TicketCard>
          <Left>
            <EventDetails>
              <Tinytext>Ticket Code: #456587</Tinytext>
              <Tinytext>Ticket Type: VVIP</Tinytext>
            </EventDetails>
          </Left>
          <Right>
            <GreenText>Checked-In</GreenText>
          </Right>
        </TicketCard>
        <TicketCard>
          <Left>
            <EventDetails>
              <Tinytext>Ticket Code: #456587</Tinytext>
              <Tinytext>Ticket Type: VVIP</Tinytext>
            </EventDetails>
          </Left>
          <Right>
            <GreenText>Checked-In</GreenText>
          </Right>
        </TicketCard>
        <TicketCard>
          <Left>
            <EventDetails>
              <Tinytext>Ticket Code: #456587</Tinytext>
              <Tinytext>Ticket Type: VVIP</Tinytext>
            </EventDetails>
          </Left>
          <Right>
            <GreenText>Checked-In</GreenText>
          </Right>
        </TicketCard>
        <TicketCard>
          <Left>
            <EventDetails>
              <Tinytext>Ticket Code: #456587</Tinytext>
              <Tinytext>Ticket Type: VVIP</Tinytext>
            </EventDetails>
          </Left>
          <Right>
            <GreenText>Checked-In</GreenText>
          </Right>
        </TicketCard>
        <TicketCard>
          <Left>
            <EventDetails>
              <Tinytext>Ticket Code: #456587</Tinytext>
              <Tinytext>Ticket Type: VVIP</Tinytext>
            </EventDetails>
          </Left>
          <Right>
            <GreenText>Checked-In</GreenText>
          </Right>
        </TicketCard>
      </EventList>
    </HomeContainer>
  );
};

export default AttendeesEvent;
