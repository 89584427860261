import React from "react";
import styled from "styled-components";
import NoVendorImg from "images/organizerevents/Frame48099015.png";

const VendorContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
  }
`;

const NoVendor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const MediumText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
`;
const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #595959;
`;

type VendorsProps = {
  event: any;
};

const Vendors: React.FC<VendorsProps> = ({ event }) => {
  return (
    <VendorContainer>
      <NoVendor>
        <img src={NoVendorImg} width="174.81px" height="174.81px" style={{ borderRadius: "10px" }} />
        <MediumText>No vendors yet</MediumText>
        <SmallText>
          Explore the vendor marketplace to request services tailored to your needs. Choose
          <br />
          from a variety of offerings or create custom requests
        </SmallText>
      </NoVendor>
    </VendorContainer>
  );
};

export default Vendors;
