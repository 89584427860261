import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, InputRef } from "antd";

const Container = styled.div`
  max-width: 100%;
  padding-right: 0.5rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
`;

const Content = styled.div``;

const CreditCard = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  width: 50%;
`;

const Bank = styled.button`
  background-color: #ffffff00;
  color: #595959;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  padding: 10px 10px;
  font-size: 13px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  width: 50%;
`;

const ExpiryCvv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 10px);
`;

const CardNumber = styled.div`
  width: calc(100% - 10px);
`;

const CreditCardContent = styled.div``;

const BankTransferContent = styled.div``;

const Pay = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Checkout: React.FC = () => {
  const [selectedButton, setSelectedButton] = useState<"A" | "B">("A");
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const onFieldsChange = () => {
    const isValid = form.getFieldsError().every(field => field.errors.length === 0);
    setIsFormValid(isValid);
  };

  return (
    <Container>
      <Top>
        <CreditCard onClick={() => setSelectedButton("A")}>Credit or debit card</CreditCard>
        <Bank onClick={() => setSelectedButton("B")}>Bank Transfer</Bank>
      </Top>

      <Content>
        {selectedButton === "A" ? (
          <CreditCardContent>
            <Form layout="vertical" form={form} name="register" scrollToFirstError onFieldsChange={onFieldsChange}>
              <CardNumber>
                <Form.Item
                  label="Card Number"
                  name="cardnumber"
                  rules={[
                    { type: "number", message: "The input is not a valid card number" },
                    { required: true, message: "Please input your card number" },
                  ]}
                >
                  <Input
                    placeholder="**** **** **** 1000"
                    style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
                  />
                </Form.Item>
              </CardNumber>
              <ExpiryCvv>
                <Form.Item
                  label="Expiry Date"
                  style={{ width: "47.5%" }}
                  name="expirydate"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="MM/YY" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
                </Form.Item>
                <Form.Item label="CVV" style={{ width: "47.5%" }} name="cvv" rules={[{ required: true }]}>
                  <Input placeholder="***" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
                </Form.Item>
              </ExpiryCvv>
              <Form.Item>
                <Pay>Pay NGN 600,050</Pay>
              </Form.Item>
            </Form>
          </CreditCardContent>
        ) : (
          <BankTransferContent>Bank transfer details displayed here</BankTransferContent>
        )}
      </Content>
    </Container>
  );
};

export default Checkout;
