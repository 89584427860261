import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { Form, Input, Select, Button, Upload, message, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { useCreateEvent, useGetUser, useGetEventById } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { routeNames } from "lib/routes";
import { useGetEventCategories } from "lib/hooks/eventcategory";
import { useEventContext } from "ui/components/event/eventContext";
import { CreateEventResponse } from "lib/hooks";
import { RcFile } from "antd/es/upload/interface";
import { ReactComponent as UploadLogo } from "images/organizerevents/upload.svg";
import { useEditEventById } from "lib/hooks";

const { TextArea } = Input;
const { Option } = Select;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const MediumText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const SmallText = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-align: center;
`;

const Bluespan = styled.span`
  color: #1390e6;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;

const initialEventValues = {
  title: "",
  banner: "https://placehold.co/600x400?text=No+Image",
  organizerEmail: "",
  category: "",
  about: "",
};

const beforeUpload = (file: RcFile): Promise<RcFile | boolean> => {
  const isSupportedFormat = ["image/svg+xml", "image/png", "image/jpeg", "image/gif"].includes(file.type);
  if (!isSupportedFormat) {
    message.error("You can only upload SVG, PNG, JPG, or GIF files!");
    return Promise.reject(false);
  }

  const img = new Image();
  img.src = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const isValidDimensions = img.width <= 800 && img.height <= 400;
      if (!isValidDimensions) {
        message.error("Image dimensions should not exceed 800x400px!");
        reject(false);
      } else {
        resolve(file);
      }
    };
  });
};

const CreateEventDetail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { mutate: createEvent } = useCreateEvent();
  const { mutate: updateEvent } = useEditEventById();
  const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useGetEventCategories();
  const userEmail = user?.email;
  const { eventId: rawEventId } = useEventContext();
  const eventId = rawEventId ? Number(rawEventId) : null;
  const { setEventId } = useEventContext();

  const organizerName = useMemo(() => {
    if (user) return `${user.firstName} ${user.lastName}`;
    return "";
  }, [user]);

  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);

  const { data: event, error: eventError } = eventId ? useGetEventById({ id: eventId }) : { data: null, error: null };

  useEffect(() => {
    if (event) {
      form.setFieldsValue({
        title: event.title,
        organizer: event.organizerEmail,
        category: event.category,
        about: event.about,
        banner: event.banner,
      });
    }
  }, [event, form]);

  const handleFinish = async (values: any) => {
    setIsSubmitting(true);
    if (!organizerEmail) {
      notification.error({ message: "Unable to find organizer details, kindly try again" });
      setIsSubmitting(false);
      return;
    }
    const payload = {
      title: values.title.trim(),
      banner: values.banner || initialEventValues.banner,
      organizerEmail,
      category: values.category.trim(),
      about: values.about.trim(),
    };

    if (eventId) {
      // Update existing event
      updateEvent(
        { id: eventId, data: payload },
        {
          onSuccess: () => {
            message.success("Event updated successfully");
            navigate(routeNames.eventType);
          },
          onError: handleError,
        },
      );
    } else {
      // Create new event
      createEvent(payload, {
        onSuccess: (data: CreateEventResponse) => {
          setEventId(Number(data.id));
          message.success("Event created successfully");
          form.resetFields();
          navigate(routeNames.eventType);
        },
        onError: handleError,
      });
    }
  };

  const handleError = (error: Error) => {
    message.error(
      axios.isAxiosError(error)
        ? error.response?.data?.message || "Failed to process request."
        : "An unexpected error occurred.",
    );
    setIsSubmitting(false);
  };

  return (
    <Container>
      <MediumText>Event Details</MediumText>
      <Form
        layout="vertical"
        style={{ width: "100%", margin: "0 auto" }}
        form={form}
        onFinish={handleFinish}
        initialValues={initialEventValues}
        requiredMark={false}
      >
        <Form.Item label="Event Banner" name="banner" style={{ width: "100%" }}>
          <Upload.Dragger
            name="banner"
            listType="picture-card"
            accept=".svg,.png,.jpg,.jpeg,.gif"
            maxCount={1}
            beforeUpload={beforeUpload}
            style={{ backgroundColor: "white" }}
          >
            <UploadLogo />
            <SmallText>
              <Bluespan>Click to upload</Bluespan> or drag and drop
            </SmallText>
            <SmallText>SVG, PNG, JPG or GIF (max. 800x400px)</SmallText>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          label="Event Title"
          name="title"
          rules={[{ required: true, message: "Please enter the event title" }]}
        >
          <Input placeholder="Enter the event title" />
        </Form.Item>

        <Form.Item label="Organizer">
          <Input value={organizerName} readOnly />
        </Form.Item>

        <Form.Item
          label="Event Category"
          name="category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select
            placeholder="Select category"
            showSearch
            loading={isCategoriesLoading}
            filterOption={(input, option) =>
              typeof option?.children === "string"
                ? String(option.children).toLowerCase().includes(input.toLowerCase())
                : false
            }
          >
            {categoriesError && <Option disabled>Error fetching categories</Option>}
            {categories?.map(category => (
              <Option key={category.id} value={category.name}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="About Event" name="about" rules={[{ required: true, message: "Please describe the event" }]}>
          <TextArea rows={4} placeholder="Describe the event for attendees to know what's up" />
        </Form.Item>

        <ButtonContainer>
          <Link to={routeNames.organizerHome}>
            <GhostButton>Cancel</GhostButton>
          </Link>
          <ButtonWrapper>
            <SubmitButton form={form} loading={isSubmitting}>
              Save and Continue
            </SubmitButton>
          </ButtonWrapper>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default CreateEventDetail;
