import React, { useState } from "react";
import styled from "styled-components";
import { BottomContainer } from "../website/landing/faq/faq.style";

const DetailsContainer = styled.div`
  margin-top: 4rem;
  padding-top: 2rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  height: 100vh;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LargeText = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 0;
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #475467;
  margin: 0;
`;

const SmallGrey = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #9c9c9c;

  margin: 0;
`;

const SmallGreen = styled.p`
  margin: 0;
`;

const MediumBlack = styled.p`
  margin: 0;
`;

const GreenText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #17b26a;
`;

const AttendeesTicketDetails = () => {
  return (
    <DetailsContainer>
      <Top>
        <LargeText>Ticket Details</LargeText>
        <SmallText>Select an event to check-in attendees.</SmallText>
      </Top>
      <Bottom>
        <Detail>
          <SmallGrey>Ticket status:</SmallGrey>
          <GreenText>Checked-In</GreenText>
        </Detail>
        <Detail>
          <SmallGrey>Event name:</SmallGrey>
          <MediumBlack>BB Naija Finale</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Ticket type:</SmallGrey>
          <MediumBlack>Regular</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Ticket code:</SmallGrey>
          <MediumBlack>32546757</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Purchased by:</SmallGrey>
          <MediumBlack>Nevaeh simmons</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Purchaser email:</SmallGrey>
          <MediumBlack>nevaehsim</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Purchaser phone number:</SmallGrey>
          <MediumBlack>+2348100011100</MediumBlack>
        </Detail>
      </Bottom>
    </DetailsContainer>
  );
};

export default AttendeesTicketDetails;
