import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { AppModal } from "ui/components/base";
import { ReactComponent as Notice } from "images/vendorprofile/Vectornotice.svg";
import { ModalBodyContainer, NoticeDisplay, NoticeText, Text } from "app/pages/organizer/vendorsprofile/Packages";
import { useEditPackage } from "lib/hooks/vendorpackage";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { getSession } from "lib/utils/utils";

const { TextArea } = Input;

type EditPackageModalProps = {
  open: boolean;
  onCancel: () => void;
  packageId: number;
  initialValues: any;
};

const EditPackageModal: React.FC<EditPackageModalProps> = ({ open, onCancel, packageId, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [vendorEmail, setVendorEmail] = useState<string | null>(null);

  const { mutate: editPackage, isError, error } = useEditPackage();

  const options = [
    { value: "NGN", label: "NGN" },
    { value: "USD", label: "USD" },
  ];

  useEffect(() => {
    const session = getSession();

    if (session) {
      setVendorEmail(session.email);
    }
  }, []);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        packageTitle: initialValues.title,
        description: initialValues.description,
        currency: initialValues.currency,
        unitprice: initialValues.price,
        upfront: initialValues.upfrontPayment,
      });
    }
  }, [initialValues, form]);

  const onFinish = (values: any) => {
    setLoading(true);

    const payload = {
      id: packageId,
      data: {
        id: packageId,
        title: values.packageTitle,
        description: values.description,
        currency: values.currency,
        vendorEmail: vendorEmail as string,
        price: parseFloat(values.unitprice),
        upfrontPayment: parseFloat(values.upfront),
        revenue: 0,
      },
    };

    editPackage(payload, {
      onSuccess: () => {
        form.resetFields();
        setLoading(false);
        onCancel();
      },
      onError: error => {
        console.error("Error editing package:", error);
        setLoading(false);
      },
    });
  };

  return (
    <AppModal open={open} onCancel={onCancel} header={<span>Edit package</span>} size="md">
      <ModalBodyContainer>
        <NoticeDisplay>
          <Notice />
          <NoticeText>Evendy charges a 5% commission on each full payment processed to you</NoticeText>
        </NoticeDisplay>

        <Form layout="vertical" form={form} name="editPackage" onFinish={onFinish}>
          <Text>Package Title</Text>
          <Form.Item name="packageTitle" rules={[{ required: true, message: "Please input the package title!" }]}>
            <Input placeholder="e.g Home service, Food trays, Hire for a day, etc" />
          </Form.Item>
          <Text>Description of Package</Text>
          <Form.Item name="description" rules={[{ required: true, message: "Please input the package description!" }]}>
            <TextArea rows={4} placeholder="Briefly state details about your package" maxLength={200} />
          </Form.Item>
          <Text>Currency for Package</Text>
          <Form.Item name="currency" rules={[{ required: true, message: "Please select a currency!" }]}>
            <Select defaultValue="NGN" options={options} />
          </Form.Item>
          <Text>Unit Price</Text>
          <Form.Item name="unitprice" rules={[{ required: true, message: "Please input the unit price!" }]}>
            <Input placeholder="" prefix="NGN" />
          </Form.Item>
          <Text>Upfront Payment Percent</Text>
          <Form.Item
            name="upfront"
            rules={[{ required: true, message: "Please input the upfront payment percentage!" }]}
          >
            <Input placeholder="" suffix="%" />
          </Form.Item>
          <Text>We recommend 10%. Value cannot be more than 20%</Text>
          {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>}
          <Form.Item>
            <SubmitButton form={form}>Save Changes</SubmitButton>
          </Form.Item>
        </Form>
      </ModalBodyContainer>
    </AppModal>
  );
};

export default EditPackageModal;
