import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EllipsisOutlined } from "@ant-design/icons";
import { ReactComponent as Price } from "images/vendorprofile/Frametotal.svg";
import { ReactComponent as Quantity } from "images/ticket/quantity.svg";
import { ReactComponent as Sales } from "images/ticket/sales.svg";
import { ReactComponent as Status } from "images/ticket/status.svg";
import { ReactComponent as Edit } from "images/vendor/Iconsedit.svg";
import { ReactComponent as Delete } from "images/vendor/Iconsdelete.svg";
import { Popover, ConfigProvider } from "antd";
import EditTicketModal from "./editTicketModal";
import DeleteTicketModal from "./deleteTicketModal";
import { AppModal } from "ui/components/base";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9faff;
  width: calc(47.5% - 30px);
  gap: 1rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Line1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Description = styled.p`
  color: #595959;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Line2 = styled.div``;

const Line3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Line4 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Line5 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Line6 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const Text1 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
`;

const Text2 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
`;

const Text3 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 2rem;
  background-color: #f9fbff;
  border-radius: 8px;
`;
const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const TicketCategory = styled.button`
  background-color: #f5f5f5;
  color: #262626;
  border: 1px solid;
  border-color: #f2f0f6;
  border-radius: 8px;
  padding: 5px 5px;
  font-size: 11px;
`;
interface EventDetails {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
  type: string;
  occurence: string;
  timezone: string;
  startDate: string;
  endDate: string;
  location: string;
  publishStatus: string;
  createdAt: string;
  updatedAt: string;
}

interface TicketCardProps {
  ticket: {
    id: number;
    category: string;
    type: string;
    description: string;
    quantity: number;
    price: number;
    image: string;
    salesStartDate: string;
    salesEndDate: string;
    minQuantity: number;
    maxQuantity: number;
    createdAt: string;
    updatedAt: string;
    event: EventDetails;
    eventId: number;
  };
  //to refresh
  onTicketAdded: () => void;
}

const arrow = false;

const TicketCard: React.FC<TicketCardProps> = ({ ticket, onTicketAdded }) => {
  const [endformattedDate, setEndFormattedDate] = useState("");
  const [endformattedTime, setEndFormattedTime] = useState("");

  //edit modal
  const [custommodalOpen, setcustomModalOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);

    setPopoverVisible(false); // Close popover
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
    onTicketAdded();
  };

  //delete modal
  const [deletemodalOpen, setdeleteModalOpen] = useState(false);

  const handledeleteModalOpen = () => {
    setdeleteModalOpen(true);
    setPopoverVisible(false); // Close popover
  };

  const handledeleteModalClose = () => {
    setdeleteModalOpen(false);
    onTicketAdded();
  };

  useEffect(() => {
    if (ticket) {
      const endDate = new Date(ticket.salesEndDate);

      const dateFormatter = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      setEndFormattedDate(dateFormatter.format(endDate));
      setEndFormattedTime(timeFormatter.format(endDate));
    }
  }, [ticket]);

  const content = (
    <MenuOption>
      <Top onClick={handlecustomModalOpen}>
        <Edit />
        <Text1>Edit ticket</Text1>
      </Top>
      <Bottom onClick={handledeleteModalOpen}>
        <Delete />
        <Text1>Delete ticket</Text1>
      </Bottom>
    </MenuOption>
  );

  return (
    <CardContainer>
      <Line1>
        <Left>
          <Text1>{ticket.type}</Text1>
          <TicketCategory>{ticket.category}</TicketCategory>
        </Left>
        <Right>
          <ConfigProvider>
            <Popover
              placement="bottom"
              content={content}
              trigger="click"
              arrow={arrow}
              visible={popoverVisible}
              onVisibleChange={setPopoverVisible}
            >
              <EllipsisOutlined />
            </Popover>
          </ConfigProvider>
        </Right>
      </Line1>
      <Line2>
        <Text2>{ticket.description}</Text2>
      </Line2>
      <Line3>
        <Left>
          <Quantity />
          <Text3>Quantity</Text3>
        </Left>
        <Right>
          {ticket.quantity} <Text2>tickets</Text2>
        </Right>
      </Line3>
      <Line4>
        <Left>
          <Price />
          <Text3>Price</Text3>
        </Left>
        <Right>
          <Text2>NGN</Text2>
          {ticket.price}
        </Right>
      </Line4>
      <Line5>
        <Left>
          <Sales />
          <Text3>Sales</Text3>
        </Left>
        <Right>
          0<Text2>/ {ticket.quantity}</Text2>
        </Right>
      </Line5>
      <Line6>
        <Left>
          <Status />
          <Text3>Status</Text3>
        </Left>
        <Right>
          <Text3>Available</Text3> <Text2>till {`${endformattedDate} at ${endformattedTime}`}</Text2>
        </Right>
      </Line6>
      <AppModal open={custommodalOpen} header="Create Ticket" onCancel={handlecustomModalClose} size="xxl">
        <EditTicketModal
          open={custommodalOpen}
          onCancel={handlecustomModalClose}
          ticketId={ticket.id}
          initialValues={ticket}
          eventId={ticket.eventId}
        />
      </AppModal>
      <DeleteTicketModal open={deletemodalOpen} onCancel={handledeleteModalClose} ticketId={ticket.id} />
    </CardContainer>
  );
};

export default TicketCard;
