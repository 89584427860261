import React from "react";
import styled from "styled-components";
import { AppModal } from "ui/components/base";
import { ReactComponent as Attention } from "images/vendor/attention.svg";
import { useDeleteEventTicket } from "lib/hooks/ticketing";
import { message } from "antd";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin: 2.5rem;
`;

const Header = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Description = styled.p`
  color: #595959;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`;

const Delete = styled.button`
  background-color: #fffafa;
  color: #b00000;
  border: none;
  border-radius: 8px;
  padding: 15px 15px;
  font-size: 18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Cancel = styled.button`
  background-color: white;
  color: #595959;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 15px 15px;
  font-size: 18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

type DeletePackageModalProps = {
  open: boolean;
  onCancel: () => void;
  ticketId: number;
};

const DeleteTicketModal: React.FC<DeletePackageModalProps> = ({ open, onCancel, ticketId }) => {
  const { mutate: deleteTicket, isError, error } = useDeleteEventTicket();

  const onFinish = () => {
    const payload = {
      id: ticketId,
    };

    deleteTicket(payload, {
      onSuccess: () => {
        onCancel(); // Close the modal after success
        message.success("Ticket deleted successfully");
      },
      onError: error => {
        console.error("Error deleting ticket:", error);
      },
    });
  };

  return (
    <AppModal open={open} onCancel={onCancel} header={<span>Delete Ticket</span>} size="md">
      <Container>
        <Attention />
        <Header>Delete Ticket?</Header>
        <Description>
          Are you sure you want to delete this ticket? You can always edit it to your preferences.
        </Description>

        <Delete onClick={onFinish}>Delete ticket</Delete>
        <Cancel onClick={onCancel}>Cancel</Cancel>
      </Container>
    </AppModal>
  );
};

export default DeleteTicketModal;
