// import { UseQueryResult, useQuery } from "@tanstack/react-query";
// import { tqQueryKeys } from "lib/constants";
// import { EvendyApi, getSession } from "lib/utils";
// import { useMemo } from "react";
// import { GetEventDto } from "ui/types";

// type GetEventsResponse = {
//   events: GetEventDto[];
//   page: number;
//   limit: number;
//   totalCount: number;
// };

// type UseGetEventsResult = UseQueryResult<GetEventsResponse, Error>;

// export function useGetOrganizerEvents(): UseGetEventsResult {
//   const session = getSession();
//   const organizerEmail = useMemo(() => {
//     return session?.email || null;
//   }, [session]);
//   return useQuery({
//     queryKey: [tqQueryKeys.event.getEvents, organizerEmail],
//     queryFn: async () => {
//       const res = await EvendyApi.get<Promise<GetEventsResponse>>(`event/get-by-email/${organizerEmail}`);
//       return res.data ?? [];
//     },
//     enabled: !!organizerEmail,
//   });
// }

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi, getSession } from "lib/utils";
import { useMemo } from "react";

type Organizer = {
  id: number;
  userEmail: string;
  avatar: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  workAddress: string | null;
  kycLevel: string;
  role: string;
  accountStatus: string;
  createdAt: string;
  organizationName: string | null;
};

type Ticket = {
  id: number;
  eventId: number;
  category: string;
  type: string;
  description: string;
  quantity: number;
  price: number;
  image: string;
  salesStartDate: string;
  salesEndDate: string;
  minQuantity: number;
  maxQuantity: number;
  createdAt: string;
  updatedAt: string;
};

type Event = {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
  type: string;
  occurence: string | null;
  timezone: string;
  startDate: string;
  endDate: string;
  location: string | null;
  publishStatus: string;
  twitter: string | null;
  facebook: string | null;
  linkedin: string | null;
  instagram: string | null;
  createdAt: string;
  updatedAt: string;
  organizer: Organizer;
  requests: any[]; // Update if more details about requests are available
  tickets: Ticket[];
};

type GetEventsResponse = {
  data: Event[];
  page: number;
  limit: number;
  totalCount: number;
};

export type GetOrganizerEventsPayload = {
  page: number;
  limit: number;
};

type UseGetEventsResult = UseQueryResult<GetEventsResponse, Error>;

export function useGetOrganizerEvents(payload: GetOrganizerEventsPayload): UseGetEventsResult {
  const session = getSession();
  const organizerEmail = useMemo(() => session?.email || null, [session]);

  const { page, limit } = payload;

  const params = {
    page,
    limit,
  };

  return useQuery({
    queryKey: [tqQueryKeys.event.getEvents, organizerEmail, ...Object.values(params)],
    queryFn: async (): Promise<GetEventsResponse> => {
      const res = await EvendyApi.get<GetEventsResponse>(`event/get-by-email/${organizerEmail}`, { params });
      return res.data;
    },
    enabled: !!organizerEmail && !!page && !!limit,
  });
}
