import React, { useState } from "react";
import styled from "styled-components";
import EventDetails from "./EventDetails";
import DateTime from "./DateTime";
import TypeLocation from "./TypeLocation";
import SocialMedia from "./SocialMedia";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type EventInformationdProps = {
  event: any;
};

const EventInformation: React.FC<EventInformationdProps> = ({ event }) => {
  return (
    <Container>
      <EventDetails event={event} />
      <DateTime event={event} />
      <TypeLocation event={event} />
      <SocialMedia event={event} />
    </Container>
  );
};

export default EventInformation;
