import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { tqMutationKeys, tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

export type CreateTicketPayload = {
  category?: string;
  type?: string;
  description?: string;
  quantity?: number;
  price?: number;
  image?: string;
  salesStartDate?: string;
  salesEndDate?: string;
  minQuantity?: number;
  maxQuantity?: number;
  eventId: number;
};

export interface CreateTicketResponse {
  id: number;
  category: string;
  type: string;
  description: string;
  quantity: number;
  price: number;
  image: string;
  salesStartDate: string;
  salesEndDate: string;
  minQuantity: number;
  maxQuantity: number;
  eventId: number;
  createdAt: string;
  updatedAt: string;
}

type UseCreateTicketResult = UseMutationResult<CreateTicketResponse, Error, CreateTicketPayload>;

export function useCreateTicket(): UseCreateTicketResult {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: CreateTicketPayload) => {
      const res = await EvendyApi.post<CreateTicketResponse>("ticket", payload); // Correct type here
      return res.data; // This will automatically unwrap the Promise
    },
    mutationKey: [tqMutationKeys.ticket.create],
    onError(createEventError: Error) {
      console.error("Error creating ticket:", createEventError);
    },
    onSuccess(data) {
      console.log("Ticket created successfully:", data);
    },
  });
}
