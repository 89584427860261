import React from "react";
import styled from "styled-components";
import { routeNames } from "lib/routes";
import { useNavigate } from "react-router-dom";
import { useEventContext } from "ui/components/event/eventContext";

const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Top = styled.div``;
const Bottom = styled.div``;
const Header = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
`;
const Text = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #595959;
`;

const ViewEvent = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;
  cursor: pointer;
`;

const GotoDAshboard = styled.button`
  background-color: white;
  color: black;
  border: 1px solid rgba(226, 232, 240, 1);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;
  cursor: pointer;
`;

const IframeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const EventCreated: React.FC = () => {
  const { eventId: rawEventId } = useEventContext();
  const eventId = rawEventId ? Number(rawEventId) : null;
  const navigate = useNavigate();

  const handleViewEvent = () => {
    navigate(`${routeNames.organizerEventManagement}/${eventId}`);
  };

  const handleGoToDashboard = () => {
    navigate(routeNames.organizerEvents);
  };

  return (
    <Container>
      <IframeContainer>
        <iframe
          src="https://lottie.host/embed/84f06ab3-c684-4b80-a736-39d7442a31da/kMwW9xzxIq.json"
          style={{ width: "200px", height: "200px", border: "none" }}
          title="Lottie Animation"
        ></iframe>
      </IframeContainer>
      <Top>
        <Header>Event Created!</Header>
        <Text>
          Your event is saved as a draft. You can easily refine details, add or manage vendors,
          <br />
          and preview, before publishing for the public
        </Text>
      </Top>
      <Bottom>
        <ViewEvent onClick={handleViewEvent}>View this event</ViewEvent>
        <GotoDAshboard onClick={handleGoToDashboard}>Go to dashboard</GotoDAshboard>
      </Bottom>
    </Container>
  );
};

export default EventCreated;
