import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
// import { ISODateString } from "ui/types";

export type EditEventPayload = {
  id: number;
  data: {
    title?: string;
    banner?: string;
    organizerEmail?: string;
    category?: string;
    about?: string;
    type?: string;
    occurence?: string;
    timezone?: string;
    startDate?: string;
    endDate?: string;
  };
};

interface EditEventResponse {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
  type: string;
  occurence: string;
  timezone: string;
  startDate: string;
  endDate: string;
}

type UseEditEventResult = UseMutationResult<EditEventResponse, Error, EditEventPayload>;

export function useEditEventById(): UseEditEventResult {
  return useMutation({
    mutationFn: async (payload: EditEventPayload) => {
      const { id, data } = payload;
      const res = await EvendyApi.patch<Promise<EditEventResponse>>(`event/${id}`, data);
      return res.data;
    },
    mutationKey: [tqMutationKeys.event.edit],
    onError(editError: Error) {
      console.log({ editError });
    },
    onSuccess(data) {
      console.log("Event updated successfully", data);
    },
  });
}
