import React from "react";
import styled from "styled-components";
import { ReactComponent as NoAttendeeIMg } from "images/organizerevents/undraw_no_data_re_kwbl1.svg";

const AttendeesContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
  }
`;

const NoAttendees = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

const MediumText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
`;
const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #595959;
`;

type AttendeesProps = {
  event: any;
};

const Attendees: React.FC<AttendeesProps> = ({ event }) => {
  return (
    <AttendeesContainer>
      <NoAttendees>
        <NoAttendeeIMg />
        <MediumText>No attendees yet</MediumText>
        <SmallText>
          Once your event is public, this is where attendees will appear as they purchase
          <br /> tickets
        </SmallText>
      </NoAttendees>
    </AttendeesContainer>
  );
};

export default Attendees;
