import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Nopackage } from "images/vendor/nopackage.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const NoTicket: React.FC = () => {
  return (
    <Container>
      <Top>
        <Nopackage />
        <Text> No Ticket Created</Text>
      </Top>
    </Container>
  );
};

export default NoTicket;
