import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EventImg from "images/organizerevents/Rectangle3294.png";
import { ReactComponent as Draft } from "images/organizerevents/draft.svg";
import { ReactComponent as Upcoming } from "images/organizerevents/Iconupcomingevents.svg";
import { ReactComponent as Concluded } from "images/organizerevents/concluded.svg";
import { ReactComponent as Edit } from "images/vendor/Iconsedit.svg";
import { Popover, ConfigProvider } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const EventName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 34%;
  margin-left: 16px;
`;
const TicketSold = styled.div`
  display: flex;
  flex-direction: column;
  width: 14%;
`;
const Attendees = styled.div`
  display: flex;
  flex-direction: column;
  width: 14%;
`;
const Revenue = styled.div`
  display: flex;
  flex-direction: row;
  width: 14%;
  align-items: center;
  gap: 0.5rem;
`;
const Status = styled.div`
  display: flex;
  flex-direction: row;
  width: 14%;
`;

const EditEvent = styled.div``;

const Number = styled.div``;

const EventImage = styled.div``;

const EventTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
`;

const MediumText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const BoldText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

const StatusButton = styled.button<{ bgColor: string; textColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  border: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  width: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.9;
  }
`;

const Text1 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
`;

const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 2rem;
  background-color: #f9fbff;
  border-radius: 8px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

interface Organizer {
  id: number;
  userEmail: string;
  avatar: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  workAddress: string | null;
  kycLevel: string;
  role: string;
  accountStatus: string;
  createdAt: string;
  organizationName: string | null;
}

interface Ticket {
  id: number;
  eventId: number;
  category: string;
  type: string;
  description: string;
  quantity: number;
  price: number;
  image: string;
  salesStartDate: string;
  salesEndDate: string;
  minQuantity: number;
  maxQuantity: number;
  createdAt: string;
  updatedAt: string;
}

interface EventCardProps {
  event: {
    id: number;
    title: string;
    banner: string;
    organizerEmail: string;
    category: string;
    about: string;
    type: string;
    occurence: string | null;
    timezone: string;
    startDate: string;
    endDate: string;
    location: string | null;
    publishStatus: string;
    twitter: string | null;
    facebook: string | null;
    linkedin: string | null;
    instagram: string | null;
    createdAt: string;
    updatedAt: string;
    organizer: Organizer;
    requests: any[]; // Update if more details about requests are available
    tickets: Ticket[];
  };
  number: number;
}

const arrow = false;

const EventCard: React.FC<EventCardProps> = ({ event, number }) => {
  const [startformattedDate, setStartFormattedDate] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (event) {
      const startDate = new Date(event.startDate);

      const dateFormatter = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      setStartFormattedDate(dateFormatter.format(startDate));
    }
  }, [event]);

  const getStatusButtonProps = (publishStatus: string) => {
    switch (publishStatus) {
      case "draft":
        return {
          text: "Draft",
          color: "#FAF8F5",
          textcolor: "#5E5300",
          Icon: Draft,
        };
      case "published":
        return {
          text: "Upcoming",
          color: "#FAFBFF",
          textcolor: "#0051AD",
          Icon: Upcoming,
        };
      case "concluded":
        return {
          text: "Concluded",
          color: "#F6FBF6",
          textcolor: "#005E0B",
          Icon: Concluded,
        };
      default:
        return {
          text: "Draft",
          color: "#FAF8F5",
          textcolor: "#5E5300",
          Icon: Draft,
        };
    }
  };

  const { text, color, textcolor, Icon } = getStatusButtonProps(event.publishStatus);

  const onclick = () => {
    navigate(`${routeNames.organizerEventManagement}/${event.id}`);
  };
  const content = (
    <MenuOption>
      <Top onClick={onclick}>
        <Edit />
        <Text1>Edit event</Text1>
      </Top>
    </MenuOption>
  );
  return (
    <Container>
      <EventName>
        <Number>{number}</Number>
        <EventImage>
          {" "}
          <img src={EventImg} width="150px" height="75px" style={{ borderRadius: "8px" }} />
        </EventImage>
        <EventTitle>
          <BoldText>{event.title}</BoldText>
          <SmallText>{startformattedDate} </SmallText>
        </EventTitle>
      </EventName>
      <TicketSold>
        <MediumText>9,678</MediumText>
        <SmallText>Expected: 10,500</SmallText>
      </TicketSold>
      <Attendees>
        <MediumText>9,678</MediumText>
        <SmallText>Expected: 10,500</SmallText>
      </Attendees>
      <Revenue>
        <SmallText>NGN</SmallText>
        <MediumText>800,250,500</MediumText>
      </Revenue>
      <Status>
        <StatusButton bgColor={color} textColor={textcolor}>
          <Icon style={{ marginRight: "8px" }} />
          {text}
        </StatusButton>
      </Status>
      <EditEvent>
        {" "}
        <ConfigProvider>
          <Popover placement="bottom" content={content} trigger="click" arrow={arrow}>
            <EllipsisOutlined />
          </Popover>
        </ConfigProvider>
      </EditEvent>
    </Container>
  );
};

export default EventCard;
