import React, { useState } from "react";
import styled from "styled-components";
import { RightOutlined, DownOutlined } from "@ant-design/icons";

interface CollapsibleProps {
  isVisible: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  gap: 1rem;
`;

const CollapseButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapsibleContent = styled.div<CollapsibleProps>`
  max-height: ${props => (props.isVisible ? "500px" : "0")};
  overflow: hidden;
  margin-left: 1rem;
  transition: max-height 0.3s ease;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;
type SocialMediaProps = {
  event: any;
};

const SocialMedia: React.FC<SocialMediaProps> = ({ event }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <Container>
      <CollapseButton onClick={toggleVisibility}>
        Social Media
        {isVisible ? <DownOutlined /> : <RightOutlined />}
      </CollapseButton>

      <CollapsibleContent isVisible={isVisible}>
        <ContentContainer>
          <Top>
            <SmallText>
              Connect social media pages to the event page – attendees can simply click to view and stay engaged
            </SmallText>
          </Top>
        </ContentContainer>
      </CollapsibleContent>
    </Container>
  );
};

export default SocialMedia;
