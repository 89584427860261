import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { Button, Form, message, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import { useEventContext } from "ui/components/event/eventContext";
import { useEditEventById } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { useGetUser, useGetEventById } from "lib/hooks";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { SeeMore } from "app/pages/website/landing/faq/faq.style";
import { ReactComponent as Select } from "images/modal/Frameselect.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

const EventTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const ComingSoonButton = styled.div`
  padding: 2px 5px;
  background-color: #fffefa;
  color: #665000;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  font-size: 9px;
  cursor: not-allowed;
  margin-left: auto;
  margin-right: 0.5rem;
`;

const EventTypeLabel = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${({ selected }) => (selected ? "#1C5B87" : "initial")};
`;

const CardDisabled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const FormBox = styled.div`
  margin-bottom: 10px;

  width: 100%;
`;

const EventTypeDescription = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ selected }) => (selected ? "#1C5B87" : "#888")};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;
const Right = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EventTypeCard = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 30%;
  height: 60px;
  border: 1px solid ${props => (props.selected ? "#1890ff" : "#d9d9d9")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const EventTypeCardDisabled = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 30%;
  height: 60px;
  border: 1px solid ${props => (props.selected ? "#1C5B87" : "#d9d9d9")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const EventType: React.FC = () => {
  const [form] = Form.useForm();
  const { eventId: rawEventId } = useEventContext();
  const eventId = rawEventId ? Number(rawEventId) : null;
  const { data: user } = useGetUser();
  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);

  const { mutate: editEvent } = useEditEventById();
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCardClick = (type: string) => {
    setSelectedType(prevType => (prevType === type ? null : type));
  };

  const { data: event, error: eventError } = eventId ? useGetEventById({ id: eventId }) : { data: null, error: null };

  //set initials
  useEffect(() => {
    if (event) {
      setSelectedType(event.type);
    }
  }, [event, form]);

  const handleSubmit = async () => {
    if (!eventId) {
      message.error("create an event first");
      return;
    }

    if (!selectedType) {
      message.error("Please select an event type.");
      return;
    }

    if (eventId !== null) {
      editEvent(
        { id: eventId, data: { type: selectedType, organizerEmail: organizerEmail } },
        {
          onSuccess: () => navigate(routeNames.eventDate),
          onError: () => message.error("Failed to update the event."),
        },
      );
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: "100%", margin: "0 auto" }}
      requiredMark={false}
    >
      <Container>
        <Title>Select Event Type</Title>
        <EventTypeContainer>
          <EventTypeCard selected={selectedType === "physical"} onClick={() => handleCardClick("physical")}>
            <Left>
              <EventTypeLabel selected={selectedType === "physical"}>Physical event</EventTypeLabel>
              <EventTypeDescription selected={selectedType === "physical"}>
                At a venue or physical location
              </EventTypeDescription>
            </Left>
            <Right visible={selectedType === "physical"}>
              <Select />
            </Right>
          </EventTypeCard>

          <EventTypeCardDisabled selected={selectedType === "online"} disabled>
            <CardDisabled>
              <EventTypeLabel>Online event</EventTypeLabel>
              <ComingSoonButton>Coming Soon</ComingSoonButton>
            </CardDisabled>
            <EventTypeDescription>Virtual live stream hosting</EventTypeDescription>
          </EventTypeCardDisabled>

          <EventTypeCardDisabled selected={selectedType === "hybrid"} disabled>
            <CardDisabled>
              <EventTypeLabel>Hybrid event</EventTypeLabel>
              <ComingSoonButton>Coming Soon</ComingSoonButton>
            </CardDisabled>

            <EventTypeDescription>Host physically & virtually</EventTypeDescription>
          </EventTypeCardDisabled>
        </EventTypeContainer>

        <Form.Item label="Location / Venue" name="locationvenue" rules={[{ required: true, message: "" }]}>
          <Input style={{ width: "100%", fontSize: "0.8rem" }} />
        </Form.Item>

        <ButtonContainer>
          <Link to={routeNames.eventDetails}>
            <GhostButton>Cancel</GhostButton>
          </Link>
          <ButtonWrapper>
            <SubmitButton form={form} loading={false}>
              Save and Continue
            </SubmitButton>
          </ButtonWrapper>
        </ButtonContainer>
      </Container>
    </Form>
  );
};

export default EventType;
